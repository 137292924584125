<template>
  <div class="left-detail" :style="{ width }">
    <div class="left-detail-header">
      <a-tooltip placement="top">
        <template #title>返回</template>
        <component
          v-if="false"
          :is="$antIcons.ArrowLeftOutlined"
          :style="{ color: '#0256FF' }"
          @click="onBack"
        />
      </a-tooltip>
      <a-tooltip placement="top">
        <template #title>编辑</template>
        <component
          :is="$antIcons.FormOutlined"
          :style="{ color: '#0256FF' }"
          @click="onEdit"
        />
      </a-tooltip>
    </div>
    <div class="left-detail-loading" v-if="loading">
      <a-spin :spinning="loading" tip="加载中..."> </a-spin>
    </div>
    <div class="left-detail-content" v-else-if="hasData">
      <slot></slot>
    </div>
    <a-empty v-else :image="Empty.PRESENTED_IMAGE_SIMPLE" />
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { useRouter } from "vue-router";
import { Empty } from "ant-design-vue";

const router = useRouter();

defineProps({
  hasData: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["edit"]);

const onBack = () => {
  router.go(-1);
};
const onEdit = () => {
  emit("edit");
};
</script>

<style lang="less" scoped>
.left-detail {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 0 20px;
  }
  &-loading {
    text-align: center;
    padding: 50px 0 0;
  }
  &-content {
    flex: 1;
    padding: 0 0 20px;
    overflow-y: auto;
  }
}
</style>
