import { defineStore } from "pinia";
import router from "@/router";
import { cloneDeep } from "lodash";
import { apiLoginOut, apiSystemMenus } from "@/api/common";
//import { useRouteCacheStore } from "@/store/modules/routeCache";

//const routeCacheStore = useRouteCacheStore();

export const useUserStore = defineStore("user", {
  state: () => ({
    sessionToken: "",
    localToken: "",
    userInfo: null,
    authInfo: null,
    menus: [],
    systems: null,
    logoUrl: "",
    currentSystemCode: "",
    isLocal: false,
  }),
  getters: {
    token: (state) => {
      if (state.isLocal) {
        return state.localToken;
      } else {
        return state.sessionToken;
      }
    },
    avatarUrl: (state) => state.userInfo?.avatar ?? "",
    isPlatformAdmin: (state) =>
      (state.userInfo?.roleInfo ?? []).some((item) => {
        return item.roleType === 1;
      }),
    isTenantAdmin: (state) =>
      (state.userInfo?.roleInfo ?? []).some((item) => {
        return item.roleType === 2;
      }),
  },
  actions: {
    async login({ authInfo, moduleMenuInfo, roleInfo, tenantLogo, isLocal }) {
      this.isLocal = isLocal;
      this.sessionToken = authInfo.token;
      this.localToken = authInfo.token;
      this.logoUrl = tenantLogo;
      this.setUserInfo({ ...authInfo, roleInfo });
      await this.initMenu(moduleMenuInfo);
      // router.push({
      //   path: this.menus[0].redirect
      //     ? this.menus[0].redirect
      //     : this.menus[0].path,
      // });
      router.push(this.menus[0].redirect
        ? this.menus[0].redirect
        : this.menus[0].path);
    },
    async logout(flag) {
      if(!flag){
        await apiLoginOut();
      }
      this.userInfo = null;
      this.sessionToken = "";
      this.localToken = "";
      this.isLocal = false;
      router.push({ path: "/login" });
    },
    setUserInfo(data) {
      this.userInfo = data;
    },
    setCurrentSystemCode(code) {
      this.currentSystemCode = code;
    },
    getPathParam(path) {
      let query = path.substring(path.indexOf("?") + 1);
      let vars = query.split("&");
      let obj = {};
      for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        obj[pair[0]] = pair[1];
      }
      return obj;
    },
    setMenus(menus = [], isFirst, moduleCode) {
      let filterMenus = cloneDeep(menus);
      this.filterPath(filterMenus);
      if (isFirst === "changeSystem") {
        //切换系统，新tab页面打开
        const { href } = router.resolve({
          path: this.getFirstRouterPath(filterMenus),
          query: { newSystemCode: moduleCode },
        });
        
        let firstFile = this.getFirstRouterPath(filterMenus);
        if((firstFile.includes('/customize/') || firstFile.includes('/centerMonitor/largeScreen')) && firstFile.includes('/#/')){
          let findIndex = href.indexOf('?newSystemCode')
          let needStrIndex = firstFile.indexOf('#/')
          let new_href = `${href.slice(0,findIndex)}${firstFile.slice(needStrIndex)}${href.slice(findIndex)}`  
          window.open(new_href, new_href);
        }else{
          window.open(href, href);
        }

        //window.open(href, href);
      
        return;
      }
      if (menus === true) {
        //当前系统,新tab页面打开
        const { href } = router.resolve({
          path: isFirst,
          query: { _blank: this.currentSystemCode, ...this.getPathParam(isFirst) },
        });
        if((isFirst.includes('/customize/') || isFirst.includes('/centerMonitor/largeScreen')) && isFirst.includes('/#/')){
          let findIndex = href.indexOf('?_blank')
          let needStrIndex = isFirst.indexOf('#/')
          let needEndStrIndex = isFirst.indexOf('?')
          let new_href = `${href.slice(0,findIndex)}${needEndStrIndex >= 0 ? isFirst.slice(needStrIndex,needEndStrIndex): isFirst.slice(needStrIndex)}${href.slice(findIndex)}`;
          window.open(new_href, new_href);
        }else{
          window.open(href, href);
        }
        return;
      }
      this.menus = filterMenus;
      if (isFirst) {
        //初次登录
        let path = this.getFirstRouterPath(filterMenus);
        router.push({ path });
      }
    },
    getFirstRouterPath(filterMenus) {
      let firstRouter = filterMenus[0];
      let path;
      let name;
      if (
        Array.isArray(firstRouter.children) &&
        firstRouter.children.length > 0
      ) {
        path = firstRouter.children[0].redirect
          ? firstRouter.children[0].redirect
          : firstRouter.children[0].path;
          name = firstRouter.children[0].menuName
      } else {
        path = firstRouter.redirect ? firstRouter.redirect : firstRouter.path;
        name = firstRouter.menuName
      }
      console.log(23232323,firstRouter, name)
      sessionStorage.setItem("customTabName", name);
      return path;
    },
    async initMenu(systemBlocks, to) {
      if (systemBlocks) {
        this.systems = systemBlocks;
        let currentSystem = systemBlocks.find((item) => item.isDefault);
        this.currentSystemCode = currentSystem.moduleCode;
        this.setMenus(currentSystem.menus, "login");
      } else {
        let res = await apiSystemMenus();
        this.systems = res.result.moduleMenus;
        this.logoUrl = res.result.tenantLogo;

        let currentSystem = this.systems.find(
          (item) => item.moduleCode == this.currentSystemCode
        );
        if (!currentSystem) {
          if (to) {
            currentSystem = this.findRouterMatchSystemCode(to.path);
          }
          if (!currentSystem) {
            currentSystem = this.systems.find((item) => item.isDefault);
          }
        }
        this.currentSystemCode = currentSystem.moduleCode;
        this.setMenus(currentSystem.menus);
      }
    },
    findRouterMatchSystemCode(matchPath) {
      let matchCode = matchPath.split("/")[1];
      return this.systems.find((item) => {
        return item.menus[0].menuPath.split("/")[1] === matchCode;
      });
    },
    filterPath(source) {
      if (Array.isArray(source)) {
        source.forEach((item) => {
          item.path = item.menuPath;
          item.name = item.menuName;
          item.icon = item.menuIcon;
          if (Array.isArray(item.children) && item.children.length > 0) {
            item.redirect = item.children[0].menuPath;
            this.filterPath(item.children);
          } else {
            item.children = null;
          }
        });
      }
    },
  },
  // 开启数据缓存
  persist: {
    enabled: true,
    strategies: [
      {
        storage: localStorage,
        paths: ["isLocal", "userInfo", "localToken"],
      },
      {
        storage: sessionStorage,
        paths: ["currentSystemCode", "sessionToken"],
      },
    ],
  },
});
