import http from "../../utils/http";

// 应用菜单接口
export const apiMessage = {
  list(params) {
    return http.request({
      url: "/cemp-bms/message/unread/list",
      method: "get",
      params,
    });
  },
  listUser(params) {
    return http.request({
      url: "/cemp-bms/user/message/unread/list",
      method: "get",
      params,
    });
  },
  unReadCount(params) {
    return http.request({
      url: "/cemp-bms/message/unread/count",
      method: "get",
      params,
    });
  },
  unReadCountUser(params) {
    return http.request({
      url: "/cemp-bms/user/message/unread/count",
      method: "get",
      params,
    });
  },
  page(params) {
    return http.request({
      url: "/cemp-bms/message/page",
      method: "get",
      params,
    });
  },
  pageUser(params) {
    return http.request({
      url: "/cemp-bms/user/message/page",
      method: "get",
      params,
    });
  },
  detail(id) {
    return http.request({
      url: "/cemp-bms/message/detail",
      method: "get",
      params: { id },
    });
  },
  read(id) {
    return http.request({
      url: "/cemp-bms/message/read",
      method: "get",
      params: { id },
    });
  },
  remove(id) {
    return http.request({
      url: "/cemp-bms/message/remove",
      method: "post",
      params: { id },
    });
  },
  save(data) {
    return http.request({
      url: "/cemp-bms/message/save",
      method: "post",
      data,
    });
  },
};
