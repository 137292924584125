<template>
    <section class="base-card">
        <div class='base-card-header' :style="style">
            <div>
                <span v-if="showTitle" class="base-card-header-title">{{ title }}</span>
                <slot name="left"></slot>
            </div>
            <slot name="right"></slot>
        </div>
        <div class="base-card-content">
            <slot></slot>
        </div>
    </section>
</template>

<script setup>
import { defineProps, computed } from 'vue';
const props = defineProps({
    title: String,
    showTitle:{
        type: Boolean,
        default: true
    },
    titleHeight: String
})

const style = computed(() => {
    return {
        height: props.titleHeight || '64px'
    }
})
</script>
<style lang='less' scoped>
.base-card {
    display: flex;
    flex-direction: column;

    &-content {
        flex: 1;
    }
}

.base-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    height: 64px;

    &-title::before {
        display: inline-block;
        content: '';
        border-left: 3px solid #0256FF;
        width: 13px;
        height: 16px;
        vertical-align: top;
    }
}

:deep .ant-descriptions-item {
    &-label {
        color: #666;
    }

    &-content {
        color: #333;
    }
}
</style>