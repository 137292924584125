import axios from "axios";
import { message, Modal } from "ant-design-vue";
import { useUserStore } from "@/store/modules/user";
export class Interceptors {
  constructor() {
    this.instance = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      timeout: 10000,
    });
    this.init();
  }
  // 初始化拦截器
  init() {
    // 请求接口拦截器
    this.instance.interceptors.request.use(
      (config) => {
        const userStore = useUserStore();
        if (userStore.token) {
          config.headers["Authorization"] = userStore.token;
        }
        if (userStore.userInfo&&userStore.userInfo.tenantId) {
          config.headers["X-Tenant-Id"] = userStore.userInfo.tenantId;
        }
        //config.headers.dacistoken = '06969719-b627-4abe-8915-1a989d9536b9'
        // if (userStore.enterpriseCode) {
        //   config.headers["landsea-enterprise"] = userStore.enterpriseCode;
        // }
        // if (userStore.currentProject?.value) {
        //   config.headers["projectId"] = userStore.currentProject?.value ?? "";
        // }
        return config;
      },
      (err) => {
        console.error(err);
      }
    );

    // 响应拦截器
    this.instance.interceptors.response.use(
      (response) => {
        const { data, config, headers } = response || {};
        if (data.success) {
          return data;
        } else {
          if (config.responseType == "blob") {
            // 导出文件流响应
            const filename = headers["content-disposition"]
              ? headers["content-disposition"].split("filename=")[1]
              : "";
            return {
              filename,
              blob: data,
            };
          } else {
            message.error(data.errorMsg);
            return Promise.reject(data);
          }
        }
      },
      (error) => {
        console.log(333, error)
        const userStore = useUserStore();
        const { data } = error.response || {};
        if (data && (data.errorCode == 401 || data.errorCode == 4003)) {
          // 登录失效
          Modal.confirm({
            title: '会话过期',
            content: '当前会话已过期，请重新登录。',
            okText: '登录',
            okType: 'primary',
            cancelText: '取消',
            onOk() {
              userStore.logout(true);
            },
            onCancel() {
              console.log('Cancel');
            },
          });
        }
        return Promise.reject(data);
      }
    );
  }
  // 返回一下
  getInterceptors() {
    return this.instance;
  }
}
