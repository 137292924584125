<template>
  <div class="page-tabs">
    <a-tabs
      class="page-tabs-tabs-bar"
      :tabBarStyle="{ margin: 0 }"
      :activeKey="activeKey"
      @change="changeTab"
    >
      <a-tab-pane
        :key="key"
        :tab="item.label"
        v-for="(item, key) in tabMap"
      ></a-tab-pane>
      <template #leftExtra>
        <slot name="leftExtra"></slot>
      </template>
      <template #rightExtra>
        <slot name="rightExtra"></slot>
      </template>
    </a-tabs>
    <div class="page-tabs-content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
defineProps({
  tabMap: {
    type: Object,
    default: () => ({}),
  },
  activeKey: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["change", "update:activeKey"]);

const changeTab = (value) => {
  emit("update:activeKey", value);
  emit("change", value);
};
</script>

<style lang="less" scoped>
.page-tabs {
  display: flex;
  flex-direction: column;
  // height: 100%;
  padding: 0 20px 20px;
  &-tabs-bar {
    font-size: 16px;
  }
  &-content {
    flex: 1;
  }
}
</style>
