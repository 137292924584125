import router from "./router";
import { useUserStore } from "@/store/modules/user";
import { useRouteCacheStore } from "@/store/modules/routeCache";
import { isPermissionMenu } from "./router/routerHelp";

const allowList = ["Login", "register", "registerResult", "NotFound"];
const loginRoutePath = "/login";
const notFoundPath = "/notFound";

router.beforeEach(async (to, from, next) => {
  const {
    query: { _blank },
    meta,
    fullPath
  } = to;
  let newSystemCode;
  if(fullPath.includes('newSystemCode=')){
    newSystemCode = fullPath.split('newSystemCode=')[1]
  }
  const userStore = useUserStore();
  const routeCacheStore = useRouteCacheStore();
  if (userStore.token) {
    if (
      to.path === loginRoutePath ||
      to.path === notFoundPath ||
     // (to.name && to.name.includes("noAuth") && to.path.startsWith("/template"))
      (to.name && to.name.includes("noAuth")) 
      //(to.path && to.path.includes("/custom/")) 
    ) {
     
      next();
    } else {
      if (userStore.systems && !newSystemCode && !_blank) {
        if (isPermissionMenu(to, userStore.menus)) {
          next();
        } else if ((meta && meta.noAuth) || (to.path && to.path.includes("/custom/"))) {
          next();
        } else {
          next({ path: notFoundPath });
        }
      } else {
        try {
          if (newSystemCode) {
  
            userStore.setCurrentSystemCode(newSystemCode);
            routeCacheStore.resetAll();
          }
          if (_blank) {
            userStore.setCurrentSystemCode(_blank);
            let currentTabInfo = JSON.parse(
              localStorage.getItem(`${userStore.currentSystemCode}`)
            );

            routeCacheStore.setAll(
              currentTabInfo.pageTabs,
              currentTabInfo.cacheViews
            );
            // window.parent.postMessage('haole')
            // routeCacheStore.resetAll();
          }
          await userStore.initMenu(null, to);
          await routeCacheStore.resetWithMenu(userStore.menus);
          let newTagetPath;
          if (
            routeCacheStore.getPageTabs[routeCacheStore.getPageTabs.length - 1]
          ) {
            newTagetPath =
              routeCacheStore.getPageTabs[
                routeCacheStore.getPageTabs.length - 1
              ].path;
          } else {
            newTagetPath = userStore.menus[0].redirect
              ? userStore.menus[0].redirect
              : userStore.menus[0].path;
          }
          if ((meta && meta.noAuth) || (to.path && to.path.includes("/custom/"))) {
            next();
            return;
          }
          let tag = isPermissionMenu(to, userStore.menus);
          if (tag) {
            next();
          } else {
            next({ path: newTagetPath });
          }
        } catch (error) {
          console.log(222, error);
          userStore.logout();
          next({ path: loginRoutePath, query: { redirect: to.fullPath } });
        }
      }
    }
  } else {
    if (allowList.includes(to.name)) {
      // 在免登录名单，直接进入
      next();
    } else {
      next({ path: loginRoutePath, query: { redirect: to.fullPath } });
    }
  }
});
