import * as XLSX from "xlsx";
import dayjs from "dayjs";

// 下载
export function downLoadFile(content, filename = "", type = "") {
  //filename 文件名，content 下载的内容
  if (!filename) {
    filename = dayjs(new Date()).format("YYYYMMDDHHmmss");
  }
  const aLink = document.createElement("a");
  const blob = new Blob([content], {
    type:
      type ||
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", //文件类型
  });
  aLink.download = filename;
  aLink.href = URL.createObjectURL(blob);
  aLink.click();
  URL.revokeObjectURL(blob);
}

//设置表格的样式
const defaultCellStyle = {
  font: { name: "Verdana", sz: 13, color: "FF00FF88" },
  fill: { fgColor: { rgb: "FFFFAA00" } },
};
//写入的样式
const wopts = {
  bookType: "xlsx",
  bookSST: false,
  type: "binary",
  cellStyles: true,
  defaultCellStyle,
  showGridLines: false,
};
export function createExcelByData(json, fields, filename = "数据") {
  json.forEach((item) => {
    for (const i in item) {
      // eslint-disable-next-line no-prototype-builtins
      if (fields.hasOwnProperty(i)) {
        item[fields[i]] = item[i];
      }
      delete item[i]; //删除原先的对象属性
    }
  });
  //工作簿对象包含一SheetNames数组，以及一个表对象映射表名称到表对象。XLSX.utils.book_new实用函数创建一个新的工作簿对象。
  const wb = XLSX.utils.book_new();

  //将JS对象数组转换为工作表。
  const ws = XLSX.utils.json_to_sheet(json, { header: Object.values(fields) });

  wb.SheetNames.push(filename);
  wb.Sheets[filename] = ws;

  const wbout = XLSX.write(wb, wopts);
  downLoadFile(s2ab(wbout), `${filename}.xlsx`);
}

const s2ab = (s) => {
  if (typeof ArrayBuffer !== "undefined") {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  } else {
    const buf = new Array(s.length);
    for (let i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
};

export function createExcelByDom(dom, filename = "数据.xlsx") {
  const wb = XLSX.utils.table_to_book(dom);
  const wbout = XLSX.write(wb, {
    bookType: "xlsx",
    bookSST: true,
    type: "array",
  });
  downLoadFile(wbout, filename, "application/octet-stream");
}
