<template>
    <svg v-bind="$attrs" class="svg-icon" aria-hidden="true" :style="getStyle">
        <use :xlink:href="symbolId" />
    </svg>
</template>
<script setup>
import { computed, defineProps } from 'vue';
// defineOptions({
//     name: 'SvgIcon',
// });
const props = defineProps({
    prefix: {
        type: String,
        default: 'icon'
    },
    name: {
        type: String,
        required: true
    },
    size: {
        type: [Number, String],
        default: 16
    }
})

const symbolId = computed(() => {
    const { prefix, name } = props
    return `#${prefix}-${name}`
})
const getStyle = computed(() => {
    const { size } = props
    const S = `${size}`.replace('px', '').concat('px')
    return {
        width: S,
        height: S
    }
})
</script>
<style lang='less' scoped>
.svg-icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}
</style>