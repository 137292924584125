<template>
  <a-layout class="basic-layout">
    <LayoutHeader></LayoutHeader>
    <a-layout>
      <LayoutSider></LayoutSider>
      <a-layout class="basic-layout-main">
        <PageTabs></PageTabs>
        <a-layout-content class="basic-layout-content">
          <router-view v-slot="{ Component, route }">
            <keep-alive :include="include">
              <component :is="Component" :key="route.name" />
            </keep-alive>
          </router-view>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </a-layout>
</template>
<script setup>
import { computed, onMounted, watch } from "vue";
import { LayoutHeader, LayoutSider, PageTabs } from "./components";
import { useRouteCacheStore } from "@/store/modules/routeCache";
import { useUserStore } from "@/store/modules/user";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";

const userStore = useUserStore();
const routeCacheStore = useRouteCacheStore();
const { cacheViews } = storeToRefs(routeCacheStore);
const route = useRoute();
const router = useRouter();

const include = computed(() => [...cacheViews.value, "BlankLayout"]);

watch(
  () => router.currentRoute.value,
  () => {
    setPageTabs(router.currentRoute.value);
  }
);

onMounted(() => {
  setPageTabs(route);
});

const setPageTabs = async (routeItem) => {
  console.log(2222, routeItem)
  const { tabName } = history.state;
  const { fullPath, meta, matched } = routeItem;
  let currentComponent = matched[matched.length - 1].components;
  let componentName = "";
  if (typeof currentComponent.default == "function") {
    const res = await currentComponent.default();
    componentName = res.default.name || res.default.__name;
  } else {
    componentName =
      currentComponent.default.name || currentComponent.default.__name;
  }
  const tabItem = {
    path: fullPath,
    title: tabName ? tabName : meta?.title ?? "",
    componentName: componentName,
    meta: meta,
  };
  // if(tabItem.title === '定制页面'){
  //   tabItem.title = sessionStorage.getItem('customTabName')
  // }
  //if(!tabName && !(routeItem.query&&routeItem.query.newSystemCode) && sessionStorage.getItem('customTabName')){
  if(!tabName && sessionStorage.getItem('customTabName')){
    let realPath = fullPath.split('?')[0]
    let findOne = userStore.menus.find(item=>item.menuPath ==realPath);
    if(findOne){
      tabItem.title = findOne.menuName
    }else{
      tabItem.title = sessionStorage.getItem('customTabName')
    }
  }

  routeCacheStore.addPageTab(tabItem);
  localStorage.setItem(
    `${userStore.currentSystemCode}`,
    JSON.stringify({
      cacheViews: routeCacheStore.getCacheViews,
      pageTabs: routeCacheStore.getPageTabs,
    })
  );
};
</script>
<style lang="less" scoped>
.basic-layout {
  height: 100%;

  &-main {
    height: 100%;
    overflow: hidden;
  }

  &-content {
    height: calc(100% - 10px);
    margin: 8px 8px 8px;
    overflow: auto;
  }
}
</style>
