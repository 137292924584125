<template>
  <div class="left-select-tree">
    <div class="left-select-tree-header">
      <component
        :is="$antIcons.UnorderedListOutlined"
        :style="{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '14px' }"
      />
      <span>{{ title }}</span>
    </div>
    <div class="left-select-tree-body">
      <div class="left-select-tree-loading" v-if="loading">
        <a-spin :spinning="loading" tip="加载中..."> </a-spin>
      </div>
      <a-tree
        v-else-if="treeData?.length"
        defaultExpandAll
        :showIcon="showIcon"
        blockNode
        :fieldNames="fieldNames"
        :selectedKeys="selectedKeys"
        :tree-data="treeData"
        @select="select"
      >
        <template #icon="{ dataRef }">
          <AuthImage v-if="!!dataRef.image" :src="dataRef.image" size="18px" />
        </template>
      </a-tree>
      <a-empty v-else :image="Empty.PRESENTED_IMAGE_SIMPLE" />
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { Empty } from "ant-design-vue";
import AuthImage from "@/components/basic/authImage/index.vue";

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  fieldNames: {
    type: Object,
    default: () => ({ children: "children", title: "label", key: "value" }),
  },
  treeData: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  showIcon: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:selected", "select"]);

const selectedKeys = computed(() => (props.selected ? [props.selected] : null));
const select = (selectedKeys, e) => {
  emit("update:selected", selectedKeys[0]);
  emit("select", selectedKeys[0], e.selectedNodes[0]);
};
</script>

<style lang="less" scoped>
.left-select-tree {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  &-header {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 46px;
    padding: 0 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    font-size: 16px;
    font-weight: bold;
    color: #38404c;
  }
  &-body {
    flex: 1;
    width: 240px;
    padding: 10px 6px;
    overflow: auto;
  }
  &-loading {
    text-align: center;
    padding: 50px 0 0;
  }
}
</style>
