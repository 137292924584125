import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import directivePlugin from "./directives/index";
import "@/assets/imgs/index.js";
import * as echarts from "echarts";

import pinia from "./store";

import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.less";

import { registerGlobComp } from "@/components/registerGlobComp";

import "./permission";

import "./styles/index.less";

import dayjs from "dayjs";
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

const app = createApp(App);
registerGlobComp(app);
app.config.globalProperties.$echarts = echarts;
app.use(router).use(pinia).use(Antd).use(directivePlugin).mount("#app");
