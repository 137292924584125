a-selecta-select
<template>
  <div class="time-range">
    <div class="time-range-list" v-if="valueList?.length">
      <div
        class="time-range-item"
        v-for="(item, index) in valueList"
        :key="index"
      >
        <a-form-item-rest>
          <a-select
            allowClear
            :value="item.timeBegin"
            placeholder="开始时间"
            :disabled="disabled"
            :options="item.startOptions"
            @change="(e) => changeStartTime(e, index)"
          ></a-select>
        </a-form-item-rest>
        -
        <a-form-item-rest>
          <a-select
            allowClear
            :value="item.timeEnd"
            placeholder="结束时间"
            :disabled="disabled"
            :options="item.endOptions"
            @change="(e) => changeEndTime(e, index)"
          ></a-select>
        </a-form-item-rest>
        <a-button
          :disabled="disabled"
          @click="remove(index)"
          style="min-width: 32px"
        >
          <template #icon>
            <DeleteOutlined :style="{ color: '#696F8C' }" />
          </template>
        </a-button>
      </div>
    </div>
    <a-button type="dashed" block @click="add">
      <template #icon>
        <PlusOutlined />
      </template>
      添加
    </a-button>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { cloneDeep } from "lodash";
const props = defineProps({
  value: {
    type: Array,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:value", "change"]);

const timeOptions = Array.from(Array(24), (_, index) => ({
  label: index,
  value: index,
  disabled: false,
}));

const valueList = computed(() => {
  return props.value&&props.value.map((item) => ({
    ...item,
    startOptions: cloneDeep(timeOptions).map((startItem) => ({
      ...startItem,
      disabled: startItem.value > item.timeEnd,
    })),
    endOptions: cloneDeep(timeOptions).map((endItem) => ({
      ...endItem,
      disabled: endItem.value < item.timeBegin,
    })),
  }));
});

const changeStartTime = (e, index) => {
  const _list = cloneDeep(props.value);
  _list[index].timeBegin = e;
  emit("update:value", _list);
  emit("change", _list);
};
const changeEndTime = (e, index) => {
  const _list = cloneDeep(props.value);
  _list[index].timeEnd = e;
  emit("update:value", _list);
  emit("change", _list);
};

const add = () => {
  const _list = cloneDeep(props.value || []);
  _list.push({
    timeBegin: undefined,
    timeEnd: undefined,
  });
  emit("update:value", _list);
  emit("change", _list);
};
const remove = (index) => {
  const _list = cloneDeep(props.value || []);
  _list.splice(index, 1);
  emit("update:value", _list?.length ? _list : null);
  emit("change", _list);
};
</script>

<style lang="less" scoped>
.time-range {
  &-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
  }
  &-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
</style>
