<template>
  <div
    class="base-chart"
    :id="id"
    :style="`width: ${width}; height: ${height};`"
  ></div>
</template>

<script setup>
import { nextTick, onMounted, onUnmounted, watch, defineProps } from "vue";
import * as echarts from "echarts";
import { getUuidCode } from "@/utils/util";
const props = defineProps({
  options: {
    type: Object,
    default: null,
  },
  width: {
    type: String,
    default: "640px",
  },
  clickFunc:{ //option还需要配置 triggerEvent: true,不然无法触发点击
    type: Function,
    default: null
  },
  height: {
    type: String,
    default: "300px",
  },
});

const id = getUuidCode();
const defaultOptions = {};
let chart = null;

watch(
  () => props.options,
  (newData) => {
    setOptions(newData);
    resize();
  },{deep:true}
);

onMounted(() => {
  initEcharts();
  if(props.clickFunc){
    chart.on('click', props.clickFunc)
  }
  window.addEventListener("resize", resize);
});
onUnmounted(() => {
  window.removeEventListener("resize", resize);
  destroyEcharts();
});
const initEcharts = () => {
  // 基于准备好的dom，初始化echarts实例
  const _chartDom = document.getElementById(id);
  if (_chartDom) {
    chart = echarts.init(_chartDom);
    setOptions(props.options);
  }
};
const destroyEcharts = () => {
  if (!chart) return;
  chart.dispose();
  chart = null;
};
const setOptions = (options = {}) => {
  chart.setOption({ ...defaultOptions, ...options }, true);
};
const resize = () => {
  nextTick(() => {
    chart.resize();
  });
};
</script>

<style lang="less" scoped></style>
