<template>
  <div class="map-container" id="container" :style="{ height }"></div>
</template>

<script setup>
import { defineProps, onMounted, ref, defineEmits, onUnmounted } from "vue";
import { shallowRef } from "@vue/reactivity";
import AMapLoader from "@amap/amap-jsapi-loader";
import { message } from "ant-design-vue";
const BEI_JING_POINT = [116.427341, 39.902842];
window._AMapSecurityConfig = {
  securityJsCode: "375910ed46dbba41d1a40fd85522be59",
};
const props = defineProps({
  height: {
    type: String,
    default: "100%",
  },
  value: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:value", "change"]);

const map = shallowRef(null);
const initMap = () => {
  AMapLoader.load({
    key: "d5cb7a2fc30861e60e84b66185595c40", // 申请好的Web端开发者Key，首次调用 load 时必填
    version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
  })
    .then((AMap) => {
      const _center = props.value.lenght ? props.value : BEI_JING_POINT;
      map.value = new AMap.Map("container", {
        viewMode: "3D", // 是否为3D地图模式
        zoom: 10, // 地图显示的缩放级别
        center: _center, // 初始化地图中心点位置
        resizeEnable: true, // 是否监控地图容器尺寸变化
        dragEnable: !props.disabled,
      });
      map.value.setDefaultCursor(props.disabled ? "default" : "pointer");
      map.value.setFitView();
      if (!props.disabled) {
        map.value.on("click", (e) => {
          const _value = [e.lnglat.lng, e.lnglat.lat];
          addMarker(_value);
        });
        initMapPlugin(AMap);
      }
      initMarker();
    })
    .catch((e) => {
      console.log(e);
    });
};
const initMapPlugin = (AMap) => {
  AMap.plugin(
    [
      "AMap.Scale",
      "AMap.ControlBar",
      "AMap.ToolBar",
      // "AMap.Geolocation",
      // "AMap.PlaceSearch",
    ],
    function () {
      map.value.addControl(new AMap.Scale());
      map.value.addControl(
        new AMap.ControlBar({
          position: {
            top: "10px",
            left: "6px",
          },
        })
      );
      map.value.addControl(
        new AMap.ToolBar({
          position: {
            top: "110px",
            left: "36px",
          },
        })
      );
      // const geolocation = new AMap.Geolocation({
      //   position: {
      //     top: "180px",
      //     left: "36px",
      //   },
      //   // enableHighAccuracy: true,
      //   zoomToAccuracy: true, // 定位成功后是否自动调整级别
      //   panToLocation: true, // 定位成功后是否自动移动到响应位置
      // });
      // map.value.addControl(geolocation);
      // geolocation.getCurrentPosition(function (status, result) {
      //   if (status == "complete") {
      //     onComplete(result);
      //   } else {
      //     onError(result);
      //   }
      // });
      // //解析定位结果
      // function onComplete(data) {
      //   message.error("定位成功");
      //   console.log(
      //     `定位结果：${data.position}。定位类别：${data.location_type}。精度：${
      //       data.accuracy
      //     }m。是否经过偏移：${data.isConverted ? "是" : "否"}`
      //   );
      // }
      // //解析定位错误信息
      // function onError(data) {
      //   message.error("定位失败");
      //   console.log(
      //     `失败原因排查信息：${data.message}。浏览器返回信息：${data.originMessage}`
      //   );
      // }
      // const placeSearch = new AMap.PlaceSearch({
      //   pageSize: 5, // 单页显示结果条数
      //   pageIndex: 1, // 页码
      //   city: "010", // 兴趣点城市
      //   citylimit: true, //是否强制限制在设置的城市内搜索
      //   map: map.value, // 展现结果的地图实例
      //   panel: "panel", // 结果列表将在此容器中进行展示。
      //   autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
      // });
      // placeSearch.search("北京大学");
    }
  );
};

const marker = ref(null);
const initMarker = () => {
  if (props.value?.length <= 0) return;
  addMarker(props.value);
};
const addMarker = (position) => {
  removeMarker();
  // eslint-disable-next-line no-undef
  marker.value = new AMap.Marker({
    position,
    draggable: !props.disabled,
    cursor: !props.disabled ? "move" : "default",
  });
  map.value.add(marker.value);
  map.value.setFitView();
  initMarkerEvent();
  if (!props.disabled) {
    emit("update:value", position);
    emit("change", position);
  }
};
// 清除点
const removeMarker = () => {
  if (marker.value) {
    map.value.remove(marker.value);
    marker.value = null;
    emit("update:value", []);
    emit("change", []);
  }
};
const initMarkerEvent = () => {
  if (!marker.value || props.disabled) return;
  marker.value.on("dragend", (e) => {
    const _value = [e.lnglat.lng, e.lnglat.lat];
    map.value.setFitView();
    emit("update:value", _value);
    emit("change", _value);
  });
};

onMounted(() => {
  initMap();
});
onUnmounted(() => {
  message.destroy();
  if (map.value) {
    map.value.destroy();
  }
});
</script>

<style lang="less" scoped>
.map-container {
  width: 100%;
  height: 100%;
}
</style>
