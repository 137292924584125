import http from "@/utils/http";

/*
 *
 * @param {*} params
 * @returns
 */

export const apiMonitorAlarm = {
  currentTypeNum(params) {
    return http.request({
      url: "/cemp-monitor/alarm/current/type/num",
      method: "get",
      params,
    });
  },
  currentList(params) {
    return http.request({
      url: "/cemp-monitor/alarm/current/page-query",
      method: "get",
      params,
    });
  },
  historyTypeNum(params) {
    return http.request({
      url: "/cemp-monitor/event/history/type/num",
      method: "get",
      params,
    });
  },
  historyList(params) {
    return http.request({
      url: "/cemp-monitor/event/history/page-query",
      method: "get",
      params,
    });
  },
  historyExport(params) {
    return http.request({
      url: "/cemp-monitor/event/history/export",
      method: "get",
      responseType: "blob",
      params,
    });
  },
  actionConfirm(data) {
    return http.request({
      url: "/cemp-monitor/alarm/confirm",
      method: "put",
      data,
    });
  },
};
