import { Interceptors } from "./interceptors";

// 请求配置
export class HttpServer {
  // 获取axios实例
  constructor() {
    this.axios = new Interceptors().getInterceptors();
  }
  // 简单封装一下方法
  request(config) {
    return new Promise((resolve, reject) => {
      this.axios(config)
        .then((res) => {
          
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

const http = new HttpServer();

export default http;
