<template>
  <a-layout-sider
    class="layout-sider"
    theme="light"
    width="180"
    collapsible
    :collapsedWidth="50"
    v-model:collapsed="collapsed"
  >
    <a-menu
      v-model:selectedKeys="selectedKeys"
      v-model:openKeys="openKeys"
      mode="inline"
      :inlineIndent="18"
      @click="onClickMenu"
    >
      <template v-for="item in menus" :key="item.path">
        <SiderMenuItem v-bind="item"></SiderMenuItem>
      </template>
    </a-menu>
    <template #trigger>
      <MenuUnfoldOutlined v-if="collapsed" />
      <MenuFoldOutlined v-else />
    </template>
  </a-layout-sider>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/store/modules/user";
import SiderMenuItem from "./SiderMenuItem.vue";
import { storeToRefs } from "pinia";

const userStore = useUserStore();
const { menus } = storeToRefs(userStore);
const route = useRoute();
const router = useRouter();

const selectedKeys = ref([]);
const openKeys = ref([]);
const collapsed = ref(false);

const initData = () => {

  const { matched, meta, fullPath } = route || {};
  const { activeMenu } = meta || {};
  openKeys.value = !collapsed.value
    ? matched.slice(1).map((item) => item.path)
    : [];
  const allSelected = matched.map((item) => item.path);
  if (
    allSelected &&
    allSelected.find((item) => item.includes("/centerMonitor/customize/"))
  ) {
    if (fullPath.includes("?_blank")) {
      let newFullPath;
      let hasMoreParam = fullPath.includes("&");
      if (hasMoreParam) {
        newFullPath = fullPath.replace(/(\?_blank).*(&)/gi, "?");
      } else {
        newFullPath = fullPath.replace(/(\?_blank).*/gi, "");
      }
      allSelected.push(newFullPath);
    } else {
      //统计报表-报表推送特殊处理
      if(fullPath.includes('/custom/jjgj/#/reportPush')){
        openKeys.value.push('/centerMonitor/statistics');
      }
      allSelected.push(fullPath);
    }
  }
  selectedKeys.value = activeMenu ? [...activeMenu] : [...allSelected];
};

const onClickMenu = (item) => {
  const { key, item: _item } = item;
  console.log(3333, key)
  const {meta} = route
  if(meta&&meta.title){
    meta.title = _item.menuName
  }
  // if (key.includes("/custom/")) {
  //   //定制页面
  //   sessionStorage.setItem("customTabName", _item.menuName);
  // }
  sessionStorage.setItem("customTabName", _item.menuName);
  
  if (_item.openWith == 1) {
    let oldSelectedKeys = selectedKeys.value
    //新开tab页面
    userStore.setMenus(true, key);
    setTimeout(()=>{
      selectedKeys.value = oldSelectedKeys
    },0)
    
  } else {
    router.push(key);
  }
};

watch(route, () => {
  initData();
});

onMounted(() => {
  initData();
});
</script>

<style lang="less" scoped>
.layout-sider {
  padding-top: 12px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);

  ::v-deep .ant-layout-sider-children {
    border-bottom: 1px solid #e6e8eb;
    overflow-y: auto;
  }

  ::v-deep .ant-layout-sider-trigger {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    height: 30px;
    line-height: 30px;
  }

  // ::v-deep .ant-menu-sub.ant-menu-inline {
  //   background: #fff;
  // }
  // ::v-deep .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  //   width: 172px;
  //   background: #f2f3f5;
  //   border-radius: 2px;
  // }
  // ::v-deep .ant-menu-sub.ant-menu-inline > .ant-menu-item {
  //   width: auto;
  //   margin: 4px;
  //   &::after {
  //     display: none;
  //   }
  // }
  // ::v-deep .ant-menu-submenu-selected .ant-menu-submenu-arrow {
  //   color: #2558e4;
  // }
}

.ant-layout-sider-has-trigger {
  padding-bottom: 30px !important;
}
</style>
