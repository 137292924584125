<template>
  <div class="page-tabs">
    <div class="tabs-list" id="scrollDom">
      <div class="tabs-item" :class="{
        'tabs-item-active':
          route.fullPath === item.path ||
          route.fullPath.includes('?newSystemCode') ||
          (route.fullPath.includes('?_blank') && (index == routeCacheStore.getPageTabs.length - 1))
      }" v-for="(item, index) in routeCacheStore.getPageTabs" :key="index" @click="changeTab(item)">
        <span class="tabs-item-text">{{ item.title }}</span>
        <close-outlined class="tabs-item-close" :style="{ fontSize: '12px', color: '#AAAAAA' }"
          @click.stop="closeTab(item, index)" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from "vue";
import { useRouteCacheStore } from "@/store/modules/routeCache";
import { useUserStore } from "@/store/modules/user";
import { useRoute, useRouter } from "vue-router";

const userStore = useUserStore();
const routeCacheStore = useRouteCacheStore();
const router = useRouter();
const route = useRoute();
const changeTab = (tabItem) => {
  let query = getPathParam(tabItem.path)
  if (tabItem.path.includes('/custom/')) {
    //定制页面
    router.push(tabItem.path)
  } else {
    router.push({
      path: tabItem.path,
      query
    });
  }
};
const getPathParam = (path) => {
  let query = path.substring(path.indexOf('?') + 1)
  let vars = query.split('&')
  let obj = {}
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=')
    obj[pair[0]] = pair[1]
  }
  return obj
}
const closeTab = (tabItem, index) => {
  if (routeCacheStore.getPageTabs.length <= 1) {
    return;
  }
  routeCacheStore.deletePageTab(tabItem, index);
  localStorage.setItem(`${userStore.currentSystemCode}`, JSON.stringify({
    cacheViews: routeCacheStore.getCacheViews,
    pageTabs: routeCacheStore.getPageTabs,
  }))
};

let scrollDiv = null;
const scrollInit = () => {

  // 获取要绑定事件的元素
  scrollDiv = document.getElementById("scrollDom");
  if (scrollDiv) {
    if (navigator.userAgent.indexOf("Firefox") >= 0) {
      // 火狐写法 添加滚轮滚动监听事件
      scrollDiv.addEventListener("DOMMouseScroll", scrollHandler, false);
    } else {
      // 非火狐 添加滚轮滚动监听事件
      scrollDiv.addEventListener("mousewheel", scrollHandler, false);
    }
  }

};
const scrollHandler = (event) => {
  if (event.preventDefault) {
    event.preventDefault();
  } else {
    event.returnValue = false;
  }
  // 获取滚动方向
  const detail = event.wheelDelta || event.detail;
  // 定义滚动方向，其实也可以在赋值的时候写
  const moveForwardStep = 1;
  const moveBackStep = -1;
  // 定义滚动距离
  let step = 0;
  // 判断滚动方向,这里的100可以改，代表滚动幅度，也就是说滚动幅度是自定义的
  if (navigator.userAgent.indexOf("Firefox") >= 0) {
    // 火狐浏览器和谷歌的值正好相反
    if (detail > 0) {
      step = moveForwardStep * 100;
    } else {
      step = moveBackStep * 100;
    }
  } else {
    if (detail < 0) {
      step = moveForwardStep * 100;
    } else {
      step = moveBackStep * 100;
    }
  }
  // 对需要滚动的元素进行滚动操作
  scrollDiv.scrollLeft += step;
};
onMounted(() => {
  scrollInit();
});
onUnmounted(() => {
  if (scrollDiv) {
    if (navigator.userAgent.indexOf("Firefox") >= 0) {
      scrollDiv.removeEventListener("DOMMouseScroll", scrollHandler, false);
    } else {
      scrollDiv.removeEventListener("mousewheel", scrollHandler, false);
    }
    scrollDiv = null;
  }
});
</script>

<style lang="less" scoped>
.page-tabs {
  background: #ffffff;

  .tabs-list {
    display: flex;
    gap: 20px;
    height: 34px;
    margin: 0 20px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    .tabs-item {
      display: flex;
      gap: 6px;
      height: 34px;
      align-items: center;
      cursor: pointer;

      &-text {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 12px;
      }
    }

    .tabs-item-active {
      .tabs-item-text {
        color: #0256ff;
        border-bottom: 2px solid #0256ff;
      }
    }
  }
}
</style>
