import http from "@/utils/http";

export const apiAlarmManage = {
    list(params) {
        return http.request({
            url: "/cemp-monitor/alarm/current/page-query",
            method: "get",
            params,
        });
    },
    getCategoryNum(projectBizIds) {
        return http.request({
            url: "/cemp-monitor/alarm/current/type/num",
            method: "get",
            params: { projectBizIds }
        });

    },
    unconfirmedCount() {
        return http.request({
            url: "/cemp-monitor/event/history/unconfirmed/count",
            method: "get",
        });
    },
    getInfo(currentId){
        return http.request({
            url: "/cemp-monitor/event/history/unconfirmed/info",
            method: "get",
            params:{currentId}
        });
    }
    
};
