<template>
  <template v-if="!children">
    <a-menu-item :key="path">
      <template v-if="!isSub">
        <svg-icon class="anticon" v-if="customIcons.includes(icon)" :name="icon" size="14"></svg-icon>
        <component v-else :is="$antIcons[icon]" />
      </template>
      <span>{{ name }}</span>
    </a-menu-item>
  </template>
  <template v-else>
    <a-sub-menu :key="path" :title="name">
      <template #icon>
        <svg-icon class="anticon" v-if="customIcons.includes(icon)" :name="icon" size="14"></svg-icon>
        <component v-else :is="$antIcons[icon]" />
      </template>
      <SiderMenuItem
        v-for="child in children"
        :key="child.path"
        v-bind="child"
        isSub
      ></SiderMenuItem>
    </a-sub-menu>
  </template>
</template>

<script setup>
import SvgIcon from "@/components/basic/svgIcon/svg-icon.vue";

import { defineProps } from "vue";
defineProps({
  name: {
    type: String,
    default: "",
  },
  path: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
  children: {
    type: Array,
    default: null,
  },
  isSub:{
    type: Boolean,
    default: false
  },
});

// 用水计划-customWaterIcon
const customIcons = ['customWaterIcon']
</script>

<style lang="less" scoped></style>
