<template>
  <a-layout-header class="layout-header">
    <div class="layout-header-left">
      <a-dropdown :trigger="['click']">
        <a class="ant-dropdown-link" @click.prevent>
          <auth-image :src="logoUrl" class="layout-header-logo" :newStyle="{ maxWidth: '100px', maxHeight: '54px' }" />
        </a>
        <template #overlay>
          <a-menu @click="onClickSystem">
            <a-menu-item v-for="item in systems" :key="item">
              {{ item.moduleName }}
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>

      <span style="font-size: 20px; color: #fff">{{ systemName }}</span>
    </div>
    <div class="layout-header-right">
      <AlarmNotice style="margin-right: 50px;" :alarmCount="alarmCount" />
      <message-notice />
      <a-dropdown placement="bottomRight" :trigger="['click']">
        <a>
          <a-avatar :size="32" style="color: #fff; background-color: #8cacff" :src="userStore.avatarUrl">
            <template #icon>
              <UserOutlined />
            </template>
          </a-avatar>
          <span class="nickName">{{ userInfo && userInfo.nickname }}</span>
        </a>
        <template v-slot:overlay>
          <a-menu class="ant-pro-drop-down menu" :selected-keys="[]" @click="onClickMenu">
            <!-- <a-menu-item key="personal">
              个人主页
            </a-menu-item> -->
            <a-menu-item key="password"> 修改密码 </a-menu-item>
            <a-menu-item key="news">我的消息</a-menu-item>
            <a-menu-item key="logout"> 退出登录 </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
      <re-password v-if="modalVisible" :visible="modalVisible" @close="modalVisible = false" @ok="handleModalOk" />
    </div>
  </a-layout-header>
</template>

<script setup>
import { computed, ref, onMounted, onUnmounted } from 'vue';
import { useRouter } from "vue-router";
import { useUserStore } from "@/store/modules/user";
import { useRouteCacheStore } from "@/store/modules/routeCache";
import { Modal } from "ant-design-vue";
//import SvgIcon from "@/components/basic/svgIcon/svg-icon.vue";
//import { useFullscreen } from '@vueuse/core';
import { storeToRefs } from "pinia";
import rePassword from "./rePassword.vue";
import { apiResetPassword } from "@/api/common.js";
import { notification } from "ant-design-vue";
import authImage from "@/components/basic/authImage/index.vue";
//import { findCurrentSystemRoutePathPefix } from "@/router/routerHelp.js";
import MessageNotice from "@/components/business/MessageNotice/index.vue";
import AlarmNotice from "@/components/business/AlarmNotice/index.vue";
const modalVisible = ref(false);
const router = useRouter();
const userStore = useUserStore();
const routeCacheStore = useRouteCacheStore();
//const { isFullscreen, toggle } = useFullscreen();
import { MQTT } from "@/utils/mqtt.js";
// import useMqtt from "@/composition/useMqtt.js";
// const { startMqtt } = useMqtt();
const alarmCount = ref(0)

const { systems, currentSystemCode, userInfo, logoUrl } =
  storeToRefs(userStore);

const onClickSystem = ({ key: { menus, moduleCode } }) => {
  userStore.setMenus(menus, "changeSystem", moduleCode);
};

const systemName = computed(() => {
  let current = {};
  if (Array.isArray(systems.value)) {
    current = systems.value.find(
      (item) => item.moduleCode === currentSystemCode.value
    );
  }
  return current.moduleName;
});

const onClickMenu = ({ key: keyName }) => {
  switch (keyName) {
    case "logout":
      Modal.confirm({
        title: "提示",
        content: "确定退出？",
        onOk: function () {
          userStore.logout();
          routeCacheStore.resetAll();
        },
      });
      break;
    case "password":
      modalVisible.value = true;
      break;
    case "news":
      gotoMyNews()
      break;
    default:
      break;
  }
};
const gotoMyNews = ()=>{
  //切换系统，新tab页面打开
  window.sessionStorage.setItem("customTabName",'我的消息');
  window.sessionStorage.setItem('myMessageTab','2')
  router.push({path:'/centerMonitor/myMessage'})
}
const handleModalOk = (info) => {
  info.id = userInfo.value.userId;
  apiResetPassword(info).then(() => {
    notification.success({
      message: "提示",
      description: "密码修改成功，即将重新登录！",
    });
    setTimeout(() => {
      userStore.logout();
    }, 2000);
  });
};

const listenMqttInstance = ref(null);
const stopListen = () => {
  listenMqttInstance.value.unsubscribe();
  listenMqttInstance.value.over();
};
const startListen = () => {
  listenMqttInstance.value = new MQTT(`/notice/alarm/count/${userInfo.value.userId}`);
  listenMqttInstance.value.init(true);
  listenMqttInstance.value.subscribe();
  listenMqttInstance.value.listen((topic, message) => {
    if (topic.includes('/notice/alarm/count')) {
      alarmCount.value = message.count
    }
  });
};

onMounted(() => {
  startListen()
})
onUnmounted(() => {
  stopListen()
})
</script>

<style lang="less" scoped>
.layout-header {
  position: relative;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  background: #0047ba;
  padding: 0 18px;
  box-shadow: 0px 4px 10px 0px rgba(78, 89, 105, 0.06), 0px 1px 0px 0px #e5e6eb;

  &-left,
  &-right {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &-left {
    gap: 20px;
  }

  &-logo {
    width: 150px;
    height: 32px;
    cursor: pointer;
  }
}

.feat-item {
  color: #fff;
  margin-right: 20px;
}

.layout-header-right {
  cursor: pointer;
}

.ant-dropdown-link {
  img {
    display: block;
    width: auto;
    height: auto;
  }
}

.nickName {
  color: #fff;
  font-size: 12px;
  margin-left: 10px;
}
</style>
