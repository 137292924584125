import ModuleList from "./business/ModuleList";
import BasicModal from "./basic/modal";
import BasicForm from "./basic/form";
import BasicCard from "./basic/card";
import BaseChart from "./basic/chart/BaseChart";
import { message } from "ant-design-vue";
import KpiPanelSelect from "./business/KpiPanelSelect/KpiPanelSelect";
import LeftSelectTree from "./business/LeftSelectTree/LeftSelectTree";
import AddressCascader from "./business/AddressCascader/AddressCascader";
import LeftDetail from "./business/leftDetail/LeftDetail";
import TwoColumnsLayout from "./basic/twoColumnsLayout/TwoColumnsLayout";
import MapContainer from "./basic/map/MapContainer";
import ComponentTabs from "./basic/tabs/ComponentTabs";
import UploadButton from "./basic/upload/UploadButton";
import DownloadButton from "./basic/download/DownloadButton";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  LockOutlined,
  ProjectOutlined,
  CloseOutlined,
  CheckOutlined,
  ZoomInOutlined,
  CloseCircleOutlined,
  RightOutlined,
  CopyrightCircleOutlined,
  SearchOutlined,
  AppstoreOutlined,
  DownOutlined,
  PlusOutlined,
  LoadingOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
  FormOutlined,
  SettingOutlined,
  FileOutlined,
  DragOutlined,
  PauseCircleOutlined,
  CaretRightOutlined,
  CaretDownOutlined,
  GoldOutlined,
  MailOutlined,
  UpSquareOutlined,
  DownSquareOutlined,
  CopyOutlined,
  BuildOutlined,
  InfoCircleOutlined
} from "@ant-design/icons-vue";

import * as antIcons from "@ant-design/icons-vue";

export function registerGlobComp(app) {
  app.component("ProjectOutlined", ProjectOutlined);
  app.component("InfoCircleOutlined", InfoCircleOutlined);
  app.component("CopyOutlined", CopyOutlined);
  app.component("FileOutlined", FileOutlined);
  app.component("UpSquareOutlined", UpSquareOutlined);
  app.component("DownSquareOutlined", DownSquareOutlined);
  app.component("MenuUnfoldOutlined", MenuUnfoldOutlined);
  app.component("MenuFoldOutlined", MenuFoldOutlined);
  app.component("UserOutlined", UserOutlined);
  app.component("LockOutlined", LockOutlined);
  app.component("CopyrightCircleOutlined", CopyrightCircleOutlined);
  app.component("CloseOutlined", CloseOutlined);
  app.component("CheckOutlined", CheckOutlined);
  app.component("ZoomInOutlined", ZoomInOutlined);
  app.component("CloseCircleOutlined", CloseCircleOutlined);
  app.component("RightOutlined", RightOutlined);
  app.component("SearchOutlined", SearchOutlined);
  app.component("AppstoreOutlined", AppstoreOutlined);
  app.component("DownOutlined", DownOutlined);
  app.component("PlusOutlined", PlusOutlined);
  app.component("LoadingOutlined", LoadingOutlined);
  app.component("EyeOutlined", EyeOutlined);
  app.component("EyeInvisibleOutlined", EyeInvisibleOutlined);
  app.component("ModuleList", ModuleList);
  app.component("BasicModal", BasicModal);
  app.component("BasicForm", BasicForm);
  app.component("BasicCard", BasicCard);
  app.component("BaseChart", BaseChart);
  app.component("KpiPanelSelect", KpiPanelSelect);
  app.component("CheckCircleFilled", CheckCircleFilled);
  app.component("CloseCircleFilled", CloseCircleFilled);
  app.component("LeftSelectTree", LeftSelectTree);
  app.component("FormOutlined", FormOutlined);
  app.component("DeleteOutlined", DeleteOutlined);
  app.component("MailOutlined", MailOutlined);
  app.component("SettingOutlined", SettingOutlined);
  app.component("DragOutlined", DragOutlined);
  app.component("AddressCascader", AddressCascader);
  app.component("TwoColumnsLayout", TwoColumnsLayout);
  app.component("LeftDetail", LeftDetail);
  app.component("MapContainer", MapContainer);
  app.component("ComponentTabs", ComponentTabs);
  app.component("UploadButton", UploadButton);
  app.component("DownloadButton", DownloadButton);
  app.component("CaretRightOutlined", CaretRightOutlined);
  app.component("CaretDownOutlined", CaretDownOutlined);
  app.component("PauseCircleOutlined", PauseCircleOutlined);
  app.component("GoldOutlined", GoldOutlined);
  app.component("BuildOutlined", BuildOutlined);
  app.config.globalProperties.$antIcons = antIcons;
}

message.config({
  maxCount: 1,
});
