a-selecta-select
<template>
  <div class="calculate-device">
    <div class="calculate-device-list" v-if="value?.length">
      <div
        class="calculate-device-item"
        v-for="(item, index) in value"
        :key="item.bizDeviceId"
      >
        <a-form-item-rest>
          <a-select
            :value="item.bizDeviceId"
            placeholder="选择计量设备"
            allowClear
            showSearch
            :options="deviceOptions"
            :fieldNames="{ label: 'name', value: 'bizDeviceId' }"
            :filterOption="filterOption"
            :disabled="disabled"
            @change="(value) => changeDevice(value, index)"
          >
          </a-select>
        </a-form-item-rest>
        <a-form-item-rest>
          <a-select
            :value="item.computeTag"
            placeholder="选择计量算法"
            allowClear
            :options="computeTagOptions"
            :disabled="disabled"
            @change="(value) => changeComputeTag(value, index)"
          >
          </a-select>
        </a-form-item-rest>
        <a-button
          :disabled="disabled"
          @click="remove(index)"
          style="min-width: 32px"
        >
          <template #icon>
            <DeleteOutlined :style="{ color: '#696F8C' }" />
          </template>
        </a-button>
      </div>
    </div>
    <a-button type="dashed" block @click="add">
      <template #icon>
        <PlusOutlined />
      </template>
      添加
    </a-button>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import { cloneDeep } from "lodash";
const props = defineProps({
  deviceOptions: {
    type: Array,
    default: () => [],
  },
  value: {
    type: Array,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:value", "change"]);
const computeTagOptions = [
  {
    label: "累加",
    value: "1",
  },
  {
    label: "扣除",
    value: "-1",
  },
];

const changeDevice = (e, index) => {
  const _list = cloneDeep(props.value);
  _list[index].bizDeviceId = e;
  emit("update:value", _list);
  emit("change", _list);
};
const changeComputeTag = (e, index) => {
  const _list = cloneDeep(props.value);
  _list[index].computeTag = e;
  emit("update:value", _list);
  emit("change", _list);
};

const add = () => {
  const _list = cloneDeep(props.value || []);
  _list.push({
    bizDeviceId: undefined,
    computeTag: undefined,
  });
  console.log(_list);
  emit("update:value", _list);
  emit("change", _list);
};
const remove = (index) => {
  const _list = cloneDeep(props.value || []);
  _list.splice(index, 1);
  emit("update:value", _list?.length ? _list : null);
  emit("change", _list);
};

const filterOption = (input, option) => {
  return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
</script>

<style lang="less" scoped>
.calculate-device {
  &-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
  }
  &-item {
    display: flex;
    gap: 8px;
  }
}
</style>
