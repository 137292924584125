<template>
  <div>
    <a-popover title="未读信息" class="mailWrap" @mouseenter="handleEnter">
      <template #content>
        <div class="mailContent" v-if="messageList&&messageList.length>0">
          <ul>
            <li v-for="(item, index) in messageList" :key="item.id">
              {{ index + 1 }}.&nbsp;&nbsp;
              <span @click="showContent(item)">{{ item.msgTitle }}</span>
              <strong>{{ item.publishTimeStr }}</strong>
              <a-button type="link" @click="handleRead(item.id)">标记已读</a-button>
            </li>
          </ul>  
          <div class="actions">
            <a-button type="primary" size="small" @click="goList"
              >更多</a-button
            >
          </div>
        </div>
        <p v-else class="no-message">暂无信息</p>
      </template>
      <a-badge :count="count" style="box-shadow: none;">
        <MailOutlined class="mail" />
      </a-badge>
    </a-popover>
    <logModal
      v-if="detailModalVisible"
      :info="currentRow"
      v-model:visible="detailModalVisible"
    ></logModal>
  </div>
</template>
<script setup>
import { apiMessage } from "@/api/backManage/message.js";
import { useRouter } from 'vue-router';
import { onMounted, ref } from "vue";
import logModal from "@/views/backManage/noticeMessage/components/logModal";

const detailModalVisible = ref(false);
const router = useRouter()
const goList = () => {
  //切换系统，新tab页面打开
  window.sessionStorage.setItem("customTabName",'我的消息');
  window.sessionStorage.setItem('myMessageTab','1')
  router.push({path:'/centerMonitor/myMessage'})
};

const messageList = ref([]);
const initList = async () => {
  apiMessage.listUser().then((res) => {
    messageList.value = res.result || [];
  });
};

const count = ref(0);
const getMessageCount = () => {
  apiMessage.unReadCountUser().then((res) => {
    count.value = res.result || 0;
  });
};

const currentRow = ref(null);
const showContent = (row) => {
  currentRow.value = row;
  detailModalVisible.value = true;
  setTimeout(()=>{
    getMessageCount();
  },2000)
};

const handleRead = (id)=>{
  apiMessage.read(id).then(()=>{
    initList();
    getMessageCount();
  })
}

const handleEnter = () => {
  initList();
};

onMounted(() => {
  getMessageCount();
  setInterval(() => {
    getMessageCount();
  }, 300000);
});
</script>
<style lang="less" scoped>
.mailWrap {
  margin-right: 30px;
}
.mailContent {
  width: 324px;
  li {
    font-size: 12px;
    display: flex;
    align-items: center;
    span {
      width: 110px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 10px;
      cursor: pointer;
      color: #0256ff;
    }
    strong {
      font-weight: normal;
    }
    button {
      font-size: 12px;
    }
  }
}
.mail {
  color: #fff;
  font-size: 20px;
  vertical-align: middle;
}
.actions {
  padding: 5px 0;
  text-align: right;
  button {
    font-size: 12px;
  }
}
.no-message{
  text-align: center;
  padding: 10px 0;
  color: #888;
}
</style>
