<template>
  <a-button
    :loading="loading"
    :disabled="disabled"
    :type="buttonType"
    @click="download"
  >
    <component :is="$antIcons.DownloadOutlined" v-if="iconVisible" />
    {{ label }}
  </a-button>
</template>

<script setup>
import { defineProps, ref } from "vue";
import { message } from "ant-design-vue";
import { downLoadFile } from "@/utils/excel";
const props = defineProps({
  label: {
    type: String,
    default: "下载",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  customFunc: {
    type: Function,
    default: null,
  },
  iconVisible: {
    type: Boolean,
    default: true,
  },
  buttonType: {
    type: String,
    default: "primary",
  },
});

const loading = ref(false);
const download = async () => {
  loading.value = true;
  try {
    let res = {};
    if (props.customFunc) {
      res = await props.customFunc();
    } else {
      // 公共下载接口
      res = await props.customFunc();
    }
    const { filename, blob } = res;
    downLoadFile(blob, decodeURI(filename));
    message.success("已下载");
  } catch (error) {
    console.log(error);
  }
  loading.value = false;
};
</script>

<style lang="less" scoped></style>
