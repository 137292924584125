<template>
  <a-input placeholder="请选择" readOnly :value="label" :disabled="disabled">
    <template #suffix>
      <svg-icon name="map" class="lonlat-map-icon" @click="openMap"></svg-icon>
    </template>
  </a-input>
  <BasicModal
    title="拾取经纬度坐标"
    width="906px"
    :destroyOnClose="true"
    :visible="modalVisible"
    @ok="ok"
    @cancel="closeMap"
  >
    <MapContainer height="520px" v-model:value="point"></MapContainer>
  </BasicModal>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, onMounted, watch } from "vue";
import SvgIcon from "@/components/basic/svgIcon/svg-icon.vue";
const props = defineProps({
  value: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["change", "update:value"]);

const label = computed(() => (props?.value ?? []).join(","));

onMounted(() => {
  initPoint();
});
watch(
  () => props.value,
  () => {
    initPoint();
  }
);

const point = ref([]);
const initPoint = () => {
  point.value = props.value;
};

const modalVisible = ref(false);
const openMap = () => {
  initPoint();
  modalVisible.value = true;
};
const closeMap = () => {
  modalVisible.value = false;
};

const ok = () => {
  emit("update:value", point.value);
  emit("change", point.value);
  modalVisible.value = false;
};
</script>

<style lang="less" scoped>
.lonlat-map-icon {
  cursor: pointer;
}
</style>
