<template>
  <BasicModal
    :title="info.msgTitle"
    width="980px"
    :closable="true"
    :visible="visible"
    okText="知道了"
    :confirmLoading="confirmLoading"
    @ok="cancel"
    @cancel="cancel"
  >
    <div class="detailwrap">
     <h1>{{ info.publishTimeStr }}</h1>
     <span>发布人：{{ info.userName }}</span>
     <p class="content">
      {{ info.msgContent }}
     </p>
    </div>
  </BasicModal>
</template>

<script setup>
import { defineProps, onMounted, defineEmits, ref } from "vue";
import { apiMessage } from "@/api/backManage/message.js";
// import dayjs from "dayjs";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  info: {
    type: Object,
    default() {
      return {};
    },
  },
});

const info = ref({})
const initData = async()=>{
  const {result} = await apiMessage.read(props.info.id)
  info.value = result || {}
}

onMounted(() => {
  
  initData()
});


const emit = defineEmits(["change", "update:visible", "success"]);
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped>
.detailwrap {
  h1,span{
    font-size: 14px;
    font-weight: normal;
    color: #888;
  }
  .content{
    margin-top: 20px;
  }
}
.project-edit-content {
  gap: 20px;
  /deep/.ant-row {
    justify-content: space-between;
  }
}
.project-edit-form {
  flex: 1;
}
/deep/.form2 {
  .ant-form-item-label {
    width: 89px;
  }
  .ant-form-item-control-input-content {
    section {
      &:first-child {
        margin-top: 0px;
      }
    }
  }
  section {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  input {
    width: 320px;
  }
}
.bt-add {
  width: 100%;
  color: #0256ff;
  background-color: #ecf4ff;
  margin-top: 16px;
  font-size: 12px;
}
</style>
