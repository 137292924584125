export default {
  beforeMount(el, binding) {
    function documentHandler(e) {
      if (el.contains(e.target)) return false;
      const { fn, currentIndex } = binding.value;
      if (fn) {
        fn(currentIndex);
      }
    }
    el.__vueClickOutSide__ = documentHandler;
    document.addEventListener("click", documentHandler);
  },
  unmounted(el) {
    document.removeEventListener("click", el.__vueClickOutSide__);
    delete el.__vueClickOutSide__;
  },
};
