<template>
  <router-view v-slot="{ Component, route }">
    <keep-alive :include="routeCacheStore.getCacheViews">
      <component :is="Component" :key="route.name" />
    </keep-alive>
  </router-view>
</template>
<script setup>
import { useRouteCacheStore } from "@/store/modules/routeCache";

const routeCacheStore = useRouteCacheStore();
</script>
