<template>
    <span style="display: inline-block;">
        <a-popover :visible="visible" trigger="click" @click="showModal">
            <template #title>
                <div class="flex-between">
                    <span>最近待确认告警</span>
                    <CloseOutlined style="cursor: pointer;" @click="closeModal" />
                </div>
            </template>
            <template #content>
                <template v-if="count > 0">
                    <a-descriptions style="width: 300px;">
                        <a-descriptions-item label="项目" :span="3" :labelStyle="{ color: '#999' }"
                            :contentStyle="{ color: '#333' }">{{ info.projectName }}</a-descriptions-item>
                        <a-descriptions-item label="对象" :span="3" :labelStyle="{ color: '#999' }"
                            :contentStyle="{ color: '#333' }">{{ info.objName }}</a-descriptions-item>
                        <a-descriptions-item label="时间" :span="3" :labelStyle="{ color: '#999' }"
                            :contentStyle="{ color: '#333' }">{{ info.time }}</a-descriptions-item>
                        <a-descriptions-item label="内容" :span="3" :labelStyle="{ color: '#999' }"
                            :contentStyle="{ color: 'red' }">{{ info.alarmDesc }}</a-descriptions-item>
                    </a-descriptions>
                    <a-divider style="margin: 0 0 10px 0;" />
                    <div class="popover-footer">
                        <a-button type="primary" size="small" @click="handleSubmit">确认</a-button>
                        <a-button @click="handleNext" size="small" :disabled="info.hasNext === 0">下一条</a-button>
                    </div>
                </template>
                <p v-else class="no-message">暂无告警</p>
            </template>
            <a-badge :count="count" style="box-shadow: none;">
                <AlertFilled style="color: #fff;font-size: 20px;vertical-align: middle;" />
            </a-badge>
        </a-popover>
    </span>
</template>
<!-- eslint-disable no-unused-vars -->

<script setup>
import { ref, onMounted, defineProps, watch } from 'vue';
import { AlertFilled } from "@ant-design/icons-vue";
import { apiMonitorAlarm } from "@/api/centerMonitor/deviceAlarm.js";

import { apiAlarmManage } from '@/api/centerMonitor/alarmManage.js';
import dayjs from 'dayjs';


const count = ref(0)
const props = defineProps(['alarmCount'])
watch(() => props.alarmCount, (v) => {
    if (props.alarmCount > count.value) {
        showModal()
    }
    count.value = v
})

onMounted(() => {
    getUnconfirmedCount()
})

const info = ref({})

const visible = ref(false);

const closeModal = () => {
    visible.value = false

}
const showModal = () => {
    visible.value = true
    getInfo()
};

const getUnconfirmedCount = () => {
    apiAlarmManage.unconfirmedCount().then(({ result }) => {
        count.value = result
    })
}

const getInfo = async (id) => {
    apiAlarmManage.getInfo(id).then(({ result }) => {
        if (result) {
            info.value = { ...result, time: dayjs(result.eventTime).format('YYYY-MM-DD HH:mm:ss') }
        } 
    })
}

const handleSubmit = () => {
    apiMonitorAlarm.actionConfirm({
        eventId: info.value.eventId,
    }).then(() => {
        if (info.value.hasNext === 0) {
            visible.value = false
        } else {
            getInfo(info.value.id)
        }
    })
}
const handleNext = () => {
    getInfo(info.value.id)
}
</script>
<style lang='less' scoped>
.popover-footer {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.no-message {
    text-align: center;
    line-height: 215px;
    width: 300px;
    vertical-align: middle;
    color: #888;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>