import { cloneDeep } from "lodash";
import { mainRouter as routes } from "./index";

export function checkHasTargetPath(targetPath, menus) {
  let findPath = false;
  let flag = false;
  for (let route of menus) {
    if (flag) break;
    if (route.path === targetPath) {
      findPath = true;
      break;
    }
    if (Array.isArray(route.children)) {
      for (let childRoute of route.children) {
        if (childRoute.path === targetPath) {
          findPath = true;
          flag = true;
          break;
        }
      }
    }
  }
  return findPath;
}

//检查子菜单对应的权限菜单是否有权限
export function checkTargetPathMenuPath(targetPath, menus) {
  let findPath = false;
  let menuRoute = [];
  let flag = false;
  let flag2 = false;
  for (let route of menus) {
    if (flag) break;
    if (route.path === targetPath) {
      findPath = true;
      break;
    }
    if (Array.isArray(route.children)) {
      for (let childRoute of route.children) {
        if (flag2) break;
        if (childRoute.path === targetPath) {
          findPath = true;
          flag = true;
          menuRoute = route;
          break;
        }
        if (Array.isArray(childRoute.children)) {
          for (let _childRoute of childRoute.children) {
            if (_childRoute.path === targetPath) {
              findPath = true;
              flag = true;
              flag2 = true;
              menuRoute = childRoute;
              break;
            }
          }
        }
      }
    }
  }
  return {
    findPath,
    menuRoute,
  };
}

export function isPermissionMenu(target, permissionMenu) {
  const { path,meta } = target;
  if(meta&&meta.noAuth)return true;
  let findSystemRoute = findCurrentSystemRoutePathPefix(path)
  if(!findSystemRoute )return false;
  //if(findSystemRoute && !permissionMenu[0].path.includes(findSystemRoute.path) )return false;

  const _routeList = cloneDeep(findSystemRoute.children);
  let hasPathFlag = checkHasTargetPath(path, permissionMenu);

  
  if (hasPathFlag) {
    return true;
  }

  //菜单子页面权限验证
  let { findPath, menuRoute } = checkTargetPathMenuPath(path, _routeList);
  if (findPath) {
    return true;
  } else {
    let hasPathFlag = checkHasTargetPath(menuRoute.redirect, permissionMenu);
    if (hasPathFlag) {
      return true;
    }
  }

  return false;

  //是菜单下面的子页面
}

//根据路径获取当前系统的所有路由
export function findCurrentSystemRoutePathPefix(path){
  return routes.find(routes=>path.includes(routes.path))
}