<template>
  <a-popover
    placement="bottomLeft"
    trigger="click"
    overlayClassName="kpi-panel-select-popover"
    @visibleChange="visibleChange"
  >
    <template #content>
      <div v-if="loading" class="kpi-panel-loading">
        <a-spin :spinning="loading" tip="加载中..."> </a-spin>
      </div>
      <template v-else>
        <div class="kpi-panel">
          <div class="kpi-tab">
            <a-tabs v-model:activeKey="activeKey" @change="tabChange">
              <template v-for="item in tabs" :key="item.key">
                <a-tab-pane
                  :key="item.key"
                  :tab="item.name"
                  v-if="item.show"
                ></a-tab-pane>
              </template>
            </a-tabs>
          </div>
          <template v-if="newOptions?.length">
            <a-row
              v-for="item in newOptions"
              :key="item.kpiSubtype"
              :gutter="16"
            >
              <a-col :span="4" class="kpi-panel-dimension-title">
                <a-typography-text
                  :style="{ width: '100%' }"
                  :ellipsis="{ tooltip: `${item.name || ''}:` }"
                  :content="`${item.name || ''}:`"
                />
              </a-col>
              <a-col :span="20" class="kpi-panel-dimension-content">
                <div
                  class="kpi-panel-item"
                  :class="{
                    'kpi-panel-item-selected': value.includes(kpiItem.value),
                  }"
                  v-for="kpiItem in item.kpis"
                  :key="kpiItem.value"
                  @click="selectKpi(kpiItem.value)"
                >
                  {{ kpiItem.label }}
                  <span
                    class="kpi-panel-item-selected-icon"
                    v-if="value.includes(kpiItem.value)"
                  >
                    <CheckCircleFilled />
                  </span>
                </div>
              </a-col>
            </a-row>
          </template>
          <div v-else class="kpi-panel-empty">
            <a-empty :image="Empty.PRESENTED_IMAGE_SIMPLE" />
          </div>
        </div>
      </template>
    </template>
    <div
      class="kpi-panel-select"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
    >
      <div
        class="kpi-panel-select-selector"
        :class="{ 'kpi-panel-select-selector-active': panelVisible }"
      >
        <div class="kpi-panel-select-selection-overflow">
          <div class="kpi-panel-select-selection-list">
            <div
              class="kpi-panel-select-selection-item"
              v-if="selectedOptionFirst"
            >
              {{ selectedOptionFirst.label }}
              <div
                class="kpi-panel-select-selection-item-remove"
                @click.prevent.stop="remove(selectedOptionFirst.value)"
              >
                <CloseOutlined />
              </div>
            </div>
            <div
              class="kpi-panel-select-selection-item kpi-panel-select-selection-item-other"
              v-if="selectedOptionsOtherLength"
            >
              + {{ selectedOptionsOtherLength }} ...
            </div>
          </div>
        </div>
        <span
          class="kpi-panel-select-selection-placeholder"
          v-if="placeholderVisible"
        >
          {{ placeholder }}
        </span>
      </div>
      <div
        class="kpi-panel-select-all-remove"
        @click.prevent.stop="allRemove"
        v-if="allRemoveIconVisible && selectedOptionFirst"
      >
        <CloseCircleFilled />
      </div>
      <div class="kpi-panel-select-arrow" v-else>
        <DownOutlined />
      </div>
    </div>
  </a-popover>
</template>

<script setup>
import { computed, defineProps, defineEmits, nextTick, ref, watch } from "vue";
import { Empty } from "ant-design-vue";
const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  value: {
    type: Array,
    default: () => [],
  },
  placeholder: {
    type: String,
    default: "请选择",
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:value", "change"]);

const placeholderVisible = computed(() => !props.value?.length);

const selectedOptions = computed(() => {
  let _selectedOptions = [];
  props.options.forEach((item) => {
    item.kpis.forEach((kpiItem) => {
      if (props.value.includes(kpiItem.value)) {
        _selectedOptions.push(kpiItem);
      }
    });
  });
  return _selectedOptions;
});
const selectedOptionFirst = computed(() => selectedOptions.value[0] || null);
const selectedOptionsOtherLength = computed(() => {
  const _length = selectedOptions.value?.length ?? 0;
  return _length ? _length - 1 : 0;
});

const selectKpi = (value) => {
  let _value = [...props.value];
  if (_value.includes(value)) {
    // 相同
    const _index = _value.indexOf(value);
    _value.splice(_index, 1);
  } else {
    // 不同
    _value.push(value);
  }
  emit("update:value", _value);
  change(_value);
};
const remove = (value) => {
  let _value = [...props.value];
  const _index = _value.indexOf(value);
  _value.splice(_index, 1);
  emit("update:value", _value);
  change(_value);
};
const allRemove = () => {
  emit("update:value", []);
  change([]);
};
const change = (value) => {
  nextTick(() => {
    emit("change", value, selectedOptions.value);
  });
};

const allRemoveIconVisible = ref(false);
const handleMouseEnter = () => {
  allRemoveIconVisible.value = true;
};
const handleMouseLeave = () => {
  allRemoveIconVisible.value = false;
};

const panelVisible = ref(false);
const visibleChange = (visible) => {
  panelVisible.value = visible;
};

const activeKey = ref("电");
const tabChange = (val) => {
  filterOptions(val);
};

const tabs = ref([
  {
    name: "电量指标",
    key: "电",
    show: true,
  },
  {
    name: "水量指标",
    key: "水",
    show: true,
  },
  {
    name: "气量指标",
    key: "气",
    show: true,
  },
  {
    name: "环境指标",
    key: "环境",
    show: true,
  },
  {
    name: "减排指标",
    key: "碳",
    show: true,
  },
]);
const newOptions = ref([]);
const filterOptions = (type) => {
  newOptions.value = props.options.filter((item) => item.kpiType === type);
};
const filterTabs = (source) => {
  tabs.value.forEach((item) => {
    let findOne = source.find((inner) => inner.kpiType === item.key);
    if (findOne) {
      item.show = true;
    }else{
      item.show = false;
    }
  });
};

watch(
  () => props.options,
  (newVal) => {
    filterTabs(newVal);
    filterOptions(activeKey.value);
  }
);
</script>

<style lang="less" scoped>
.kpi-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 8px 20px;
  &-dimension {
    &-title {
      font-weight: bold;
      color: rgba(0, 0, 0, 0.85);
    }
    &-content {
      display: flex;
      flex-wrap: wrap;
      gap: 12px 16px;
      .kpi-panel-item {
        position: relative;
        padding: 0 16px;
        height: 32px;
        line-height: 32px;
        border-radius: 2px;
        border: 1px solid #d9d9d9;
        color: #38404c;
        cursor: pointer;
        &:hover {
          color: #0256ff;
        }
        &-selected {
          color: #0256ff;
        }
        &-selected-icon {
          position: absolute;
          display: flex;
          top: -7px;
          right: -7px;
          color: #0256ff;
          font-size: 14px;
        }
      }
    }
  }
}
.kpi-panel-loading,
.kpi-panel-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.kpi-panel-select {
  position: relative;
  cursor: pointer;
  width: auto;
  min-width: 180px;
  &-selector {
    position: relative;
    width: 100%;
    height: 32px;
    padding: 0 11px;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &:hover {
      border-color: #4d7ef0;
    }
    &-active {
      border-color: #4d7ef0;
      box-shadow: 0 0 0 2px rgba(37, 88, 228, 0.2);
    }
  }
  &-selection-overflow {
    height: 100%;
    padding-right: 18px;
  }
  &-selection-list {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 100%;
  }
  &-selection-item {
    display: flex;
    align-items: center;
    gap: 4px;
    max-width: 100%;
    height: 24px;
    padding-left: 8px;
    padding-right: 4px;
    background: #f5f5f5;
    border: 1px solid #f0f0f0;
    border-radius: 2px;
    cursor: default;
    transition: font-size 0.3s, line-height 0.3s, height 0.3s;
    &-remove {
      cursor: pointer;
      font-size: 10px;
      color: #00000073;
      transition: color 0.3s ease, opacity 0.15s ease;
      &:hover {
        color: inherit;
      }
    }
    &-other {
      padding-right: 8px;
    }
  }
  &-selection-placeholder {
    position: absolute;
    top: 50%;
    left: 11px;
    transform: translateY(-50%);
    transition: all 0.3s;
    color: #bfbfbf;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 18px;
  }
  &-arrow {
    position: absolute;
    top: 0;
    right: 11px;
    margin-top: 6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
  }
  &-all-remove {
    position: absolute;
    top: 0;
    right: 11px;
    margin-top: 6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    transition: color 0.3s ease, opacity 0.15s ease;
    &:hover {
      color: rgba(0, 0, 0, 0.45);
    }
  }
}
</style>
<style lang="less">
.kpi-panel-select-popover {
  padding-top: 0 !important;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner-content {
    width: 590px;
    height: 594px;
    padding: 20px 0;
    overflow-y: auto;
  }
}
</style>
