<template>
  <a-cascader
    :fieldNames="{
      label: 'addressName',
      value: 'addressCode',
      children: 'children',
    }"
    showSearch
    :placeholder="placeholder"
    :disabled="disabled"
    :value="value"
    :options="options"
    @change="changeAddress"
  />
</template>

<script setup>
import { defineProps, ref, defineEmits } from "vue";
import { apiAddressList } from "@/api/common.js";
defineProps({
  value: {
    type: Array,
    default: () => [],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "请选择",
  },
});
const emit = defineEmits(["update:value", "change"]);
const options = ref([]);
const initOptions = async () => {
  apiAddressList().then((res) => {
    options.value = res.result;
  });
};
initOptions();
const changeAddress = (value, selectedOptions) => {
  emit("update:value", value);
  emit("change", value, selectedOptions);
};
</script>

<style lang="less" scoped></style>
