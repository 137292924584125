<template>
  <a-modal
    wrapClassName="basic-modal"
    :bodyStyle="bodyStyle"
    :width="width"
    :closable="closable"
    :keyboard="keyboard"
    :destroyOnClose="destroyOnClose"
    :maskClosable="maskClosable"
    :centered="centered"
    :title="title"
    :visible="visible"
    :okText="okText"
    :cancelText="cancelText"
    :confirm-loading="confirmLoading"
    :footer="footer"
    @ok="ok"
    @cancel="cancel"
  >
    <slot></slot>
  </a-modal>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
defineProps({
  title: {
    type: String,
    default: "",
  },
  visible: {
    type: Boolean,
    default: false,
  },
  closable: {
    type: Boolean,
    default: false,
  },
  keyboard: {
    type: Boolean,
    default: false,
  },
  maskClosable: {
    type: Boolean,
    default: false,
  },
  confirmLoading: {
    type: Boolean,
    default: false,
  },
  centered: {
    type: Boolean,
    default: false,
  },
  destroyOnClose: {
    type: Boolean,
    default: false,
  },
  okText: String,
  cancelText: String,
  footer: String,
  width: {
    type: [String, Number],
  },
});
const emit = defineEmits(["ok", "cancel"]);

const bodyStyle = {
  padding: "20px 36px",
};

const ok = () => {
  emit("ok");
};
const cancel = () => {
  emit("cancel");
};
</script>

<style lang="less">
.basic-modal {
  .ant-modal-title {
    line-height: 1;
    font-weight: bold;
  }
  .ant-modal-body {
    max-height: calc(100vh - 260px);
    overflow: auto;
  }
}
</style>
